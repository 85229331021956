<template>
  <Banner bg-image="yellow-bg.jpg" talk-img="breathing.png">
    <template v-slot:title>Practice breathing exercises</template>
    <template v-slot:subtitle>
      The breathing techniques below can help you slow down your breathing. They
      work by distracting your mind as you count, calming your nervous system,
      and decreasing stress in your body. By controlling your breath, you can
      move past emotional blocks.
    </template>
  </Banner>
  <section class="tabs-sect">
    <div class="max-w-6xl px-4 mx-auto my-10 sm:px-6"></div>
    <div class="max-w-6xl px-4 mx-auto my-10 sm:px-6">
      <div class="mb-4 text-2xl font-bold call-text md:text-3xl text-blue">
        Fight State - Breathing Lying Down
      </div>
      <p class="mb-6">
        Learn how to properly engage in a lying down breathing exercise.
      </p>
      <div class="aspect-w-16 aspect-h-7">
        <iframe
          src="https://www.youtube.com/embed/QwIox-ITfWc"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
    <div class="max-w-6xl px-4 mx-auto my-10 sm:px-6">
      <div class="mb-4 text-2xl font-bold call-text md:text-3xl text-blue">
        Breathing on Your Back Feet Up
      </div>
      <p class="mb-6">
        If you can't get comfortable on your stomach or if you are outside, this
        is a great breathing position to ground yourself.
      </p>
      <div class="aspect-w-16 aspect-h-7">
        <iframe
          src="https://www.youtube.com/embed/h0kkQVydkEk"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
    <div class="max-w-6xl px-4 mx-auto my-10 sm:px-6">
      <div class="mb-4 text-2xl font-bold call-text md:text-3xl text-blue">
        Fight State - Breathing Lying Down
      </div>
      <p class="mb-6">
        If you are finding it hard to find rhythm in your breath, use this
        breathing position and engage in deep, long breaths to find your center.
      </p>
      <div class="aspect-w-16 aspect-h-7">
        <iframe
          src="https://www.youtube.com/embed/r_dwpLf8be0"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
    <div class="max-w-6xl px-4 mx-auto my-10 sm:px-6">
      <div class="mb-4 text-2xl font-bold call-text md:text-3xl text-blue">
        Freeze State - Breathing Lying Down
      </div>
      <p class="mb-6">
        If you are feeling numb or stuck, use this freeze state method of
        breathing to restore your feeling. Continue to take long and deep
        breaths as counted in the video in this position.
      </p>
      <div class="aspect-w-16 aspect-h-7">
        <iframe
          src="https://www.youtube.com/embed/KEfda_cJLNM"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
    <div class="max-w-6xl px-4 mx-auto my-10 sm:px-6">
      <div class="mb-4 text-2xl font-bold call-text md:text-3xl text-blue">
        Seated Breathing
      </div>
      <p class="mb-6">
        This seated breathing practice is accessible in many environments.
      </p>
      <div class="aspect-w-16 aspect-h-7">
        <iframe
          src="https://www.youtube.com/embed/X4AYdoXL5sI"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
    <div class="max-w-6xl px-4 mx-auto my-10 sm:px-6">
      <div class="mb-4 text-2xl font-bold call-text md:text-3xl text-blue">
        Standing Breathing
      </div>
      <p class="mb-6">
        If you are feeling numb or stuck, use this freeze state method of
        breathing to restore your feeling. Continue to take long and deep
        breaths as counted in the video in this position.
      </p>
      <div class="aspect-w-16 aspect-h-7">
        <iframe
          src="https://www.youtube.com/embed/51bAEr7lP3A"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </section>
  <FooterCard
    img="help-for-someone-else-braid.png"
    text="View Tools & resources"
    name="ToolsAndResources"
  >
    <template v-slot:header>Try another coping strategy</template>
    <template v-slot:content>
      Different coping strategies resonate with different people.
      <br />
      Discover more coping strategies and add to your toolbox.
    </template>
  </FooterCard>
</template>

<script>
import Banner from "@/components/Banner.vue";
import FooterCard from "@/components/FooterCard.vue";
export default {
  name: "PracticeBreathingExercises",
  components: {
    Banner,
    FooterCard,
  },
};
</script>

<style lang="scss" scoped></style>
